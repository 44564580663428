import * as $ from 'jquery'

export default {

    init() {

        let projects = $('.filter-container li');
        console.log("projects:", projects)
        for (let i = 0; i < projects.length; i++) {

            let $mask = $(`.filter-container li:nth-child(${i + 1}) .mask`)
            console.log("mask:", $mask);
            let $desc = $(`.filter-container li:nth-child(${i + 1}) .desc`)
            let $descSpan = $(`.filter-container li:nth-child(${i + 1}) .desc span`)

            $descSpan.prependTo($mask);

            let $maskSpan = $(`.filter-container li:nth-child(${i + 1}) .mask span`)
            const replaced = $maskSpan.html().replace(/,/g, '')
            $maskSpan.html(replaced)

        }

        let filters = $('.filter-container li')
        for (let i = 0; i < filters.length; i++) {

            let $filter = $(`.filter-container li:nth-child(${i + 1}) .mask`)
            let link = $(`.filter-container li:nth-child(${i + 1}) .desc h4 a`).attr('href')
            console.log("link:", link)

            $filter.click(e => {
                e.preventDefault()
                window.location = link;
            })

        }

    },

    finalize() {



    },


}